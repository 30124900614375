<template>
  <div>
    <portal to="v-main">
      <work-dialog v-model="showWorkDialog" :id="idEditWork" :object_="data" :lastRow="lastWork" />
    </portal>
    <v-card-text style="height: 300px; overflow: auto" class="pa-0">
      <a-table-f-api
        v-if="id"
        ref="tableWorks"
        api="/mechti/construction-schedule"
        :model="model"
        :searchable="false"
        :defaults="{
          filters: { object_id: id },
          sort: { key: 'date_start', order: 'ASC' },
          paramName: 'work',
        }"
        @lastRow="setLastWork($event)"
        @click="onClickWorkRow($event)"
      >
        <template v-slot:top v-if="getAccess('object.workEdit', { access: data.object_access })">
          <v-fab-transition>
            <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="createNewWork">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </a-table-f-api>
    </v-card-text>
  </div>
</template>

<script>
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {
    workDialog: () => import("@/views/dialogs/workDialog"),
  },
  props: {
    id: Number,
    data: Object,
  },
  data() {
    return {
      idEditWork: 0,
      showWorkDialog: false,
      lastWork: {},
    };
  },
  created() {},
  watch: {
    showWorkDialog() {
      if (!this.showWorkDialog) {
        this.$refs.tableWorks.updateData();
      }
    },
  },
  computed: {
    model() {
      let model = [];
      let list = this.getModelList(this.$store.getters["config/get"].models.works);
      //let list = this.$store.getters["config/get"].models.works.list || [];
      if (this.$root.profile.role == 1000) {
      }
      list.forEach((l) => {
 
        if (!["actions", "checked"].includes(l.name)) model.push(l);
      });

      return model;
    },
  },
  methods: {
    onClickWorkRow(d) {
      if (this.$root.profile.role <= 20) return;
      this.showEditDialogWorkFunc(d.row.id);
    },

    createNewWork() {
      this.showEditDialogWorkFunc(0);
    },
    showEditDialogWorkFunc(id) {
      this.idEditWork = id;
      this.showWorkDialog = true;
    },
    setLastWork(v) {
      this.lastWork = v;
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
